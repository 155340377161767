import React from "react";
import { toast } from "react-toastify";
import { GoogleLogin } from "react-google-login";

const clientId =
  "356394307734-0576bulqkuvvtgtvbsr1v4mm24o1vcad.apps.googleusercontent.com";
const onSuccess = (res) => {
  console.log("Login Success Current User", res.profileObj);
  console.log("Login Success Current User email", res.profileObj.email);
  
  localStorage.setItem("emailId",res.profileObj.email);
  localStorage.setItem("name",res.profileObj.name);
  
  toast.success("Google Login Success");
};
const onFailure = (res) => {
  console.log("Login Failed", res);
  toast.error("Google Login Failed");
};

const Login = (props) => {
  return (
    <>
      <div id="LoginInButton">
        <GoogleLogin
          clientId={clientId}
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
        />

        <div id="facebookButton">
          <button onClick={props.handleFacebookLogin}>
     
            <img src="./facebook.png" width="40px" /> <span>Login</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
