import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { apiurl,reqInstance } from "../globals";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import '../css/create-session.css'
function CreateSession(props) {
  const [randomCode, setRandomCode] = useState("");
  const [createSession, SetCreateSession] = useState(true);
  const [sessionCreated, setSessionCreated] = useState(false);
  const [preparingQuestions, setPreparintQuestions] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const navigate = useNavigate();
  const { startCreateSession } = props;
  const [defaultEmail, setDefaultEmail] = useState(
    localStorage.getItem("emailId") || ""
  );
    const [isPublic,setIsPublic] = useState(false)
    const [isPreview,setIsPreview] = useState(false)
    const [isPlaySameTimeRequired,setIsPlaySameTimeRequired] = useState(false) 
    const [isSessionCodeSuccessful,setIsSessionCodeSuccessful] = useState(false)
  const uuid = uuidv4();
  // const [formData, setFormData] = useState({
  //   ID: uuid,
  //   SessionCode: randomCode,
  //   DatePlayed: new Date(),
  //   OrganizedBy: "",
  //   NoOfQuestions: "",
  //   NoOfPlayers: "",
  //   OrganizerEmailId: "",
  //   OrganizerPhone: "",
  //   CountryCode: "USA",
  //   EventType: "",
  //   EventGroup: "",
  //   EventCategory: "",
  //   EventSubCategory: "",
  //   Description: "",
  // });

  const joinSessionNewWindow = () => {
    navigate(`/joinquizsession?sessionid=${randomCode}`);
    // window.open(`${websiteurl}/joinquizsession?sessionid=${randomCode} `, '_blank');
  };

  const handleInputChange = (e, field) => {
    console.log(e, field, "Fielddddd");
    // if (field === "SessionCode") {
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     [field]: randomCode,
    //   }));
    // } else {
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     [field]: e.target.value,
    //   }));
    // }
  };

  const handleRetry = (e) => {
    console.log("handle retry called");
    startCreateSession();
  };

  // Form Validation
  const createSessionValidation = yup.object().shape({
    SessionName: yup.string().max(50).required("Please Enter Session Name!"),
    NoOfQuestions: yup.number().required("Please Enter No of Questions!"),
    NoOfPlayers: yup.number().required("Please Enter No of Players!"),
    OrganizerEmailId: yup
      .string()
      .email()
      .required("Please Enter Organizer Email ID!"),
    Description: yup.string().required("Please Enter Description!"),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(createSessionValidation),
  });

  // -------------

  // Data

  const onSubmit = async (event) => {
    console.log("My event data", event);
    
    SetCreateSession(false);
    setPreparintQuestions(true);

    //* This work is Panding handleInputChange
    handleInputChange(null, "SessionCode");

    const updateData = {
      ...event,
      ID: uuid,
      SessionCode: randomCode,
      DatePlayed: new Date(),
      CountryCode: "USA",
      'IsPublicVisibility':isPublic,
      'IsReviewRequired':isPreview,
      'IsPlaySameTimeRequired':isPlaySameTimeRequired
    };
    console.log("Updated data ====>", updateData);

    if (updateData.SessionCode === "") {
      if (randomCode === "") {
        return;
      } else {
        updateData.SessionCode = randomCode;
      }
    }

    reqInstance
      .post(`${apiurl}/api/SessionDetails`, updateData)
      .then((response) => {
        // Handle the API response
        console.log(response.data);
        setIsSessionCodeSuccessful(true)
        setPreparintQuestions(false);
        setSessionCreated(true);
        //check for isPreview
        //todo
        if(isPreview){
        // window.location.replace('/previewQuestions/?session-id='+response.data.id+'&&session-code='+response.data.sessionCode);
        navigate(`/previewQuestions/?session-id=${response.data.id}&&session-code=${response.data.sessionCode}`);
        }
        setErrorOccured(false);
        //show that session created and show a link to join the quiz
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        console.error("Error:", error);
        setPreparintQuestions(false);
        setSessionCreated(false);
        setErrorOccured(true);
      });
  };

  const generateRandomCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let code = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }

    return code;
  };

  useEffect(() => {
    setRandomCode(generateRandomCode());
    // const emailFromLocalStorage = localStorage.getItem("emailId");
    // if (emailFromLocalStorage) {
    //   console.log('localstorage',emailFromLocalStorage);
    //   setDefaultEmail(emailFromLocalStorage);
    // }
  }, []);

  return (
    <section
      className="d-flex align-items-center"
      style={{ display: `${true ? "block" : "none"}`, minHeight: "100vh" }}
    >
      <div className="container">
        <div className="row p-0 m-0 align-items-center justify-content-center">
          <div className="col-lg-8">
            <h2 className="fw-bold mb-4 text-center">
              Create New Quiz Session
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row m-0 p-0 justify-content-center">
                <div className="col-md-8">
                  <Controller
                    name="SessionName"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Session Name"
                        {...field}
                      />
                    )}
                  />
                  {errors.SessionName && (
                    <p style={{ color: "#dc3545" }} className="p-0 m-0 mt-1">
                      {errors.SessionName.message}
                    </p>
                  )}
                </div>

                <div className="col-md-8 mt-3">
                  <Controller
                    name="NoOfQuestions"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter No of Questions. Max (10)"
                        {...field}
                      />
                    )}
                  />
                  {errors.NoOfQuestions && (
                    <p style={{ color: "#dc3545" }} className="p-0 m-0 mt-1">
                      {errors.NoOfQuestions.message}
                    </p>
                  )}
                </div>

                <div className="col-md-8 mt-3">
                  <Controller
                    name="NoOfPlayers"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter No of Players"
                        {...field}
                      />
                    )}
                  />
                  {errors.NoOfPlayers && (
                    <p style={{ color: "#dc3545" }} className="p-0 m-0 mt-1">
                      {errors.NoOfPlayers.message}
                    </p>
                  )}
                </div>

                <div className="col-md-8 mt-3">
                  <Controller
                    name="OrganizerEmailId"
                    control={control}
                    defaultValue={defaultEmail}
                    render={({ field }) => (
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Organizer Email ID"
                        {...field}
                      />
                    )}
                  />
                  {errors.OrganizerEmailId && (
                    <p style={{ color: "#dc3545" }} className="p-0 m-0 mt-1">
                      {errors.OrganizerEmailId.message}
                    </p>
                  )}
                </div>

                {/* <div className="col-md-8 mt-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Organizer Phone Number. (optional)"
                  />
                </div> */}

                <div className="col-md-8 mt-3">
                  <Controller
                    name="Description"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="2"
                        {...field}
                        placeholder="Describe in 200 words what kind of questions you need to AI Quiz generator."
                      ></textarea>
                    )}
                  />
                  {errors.Description && (
                    <p style={{ color: "#dc3545" }} className="p-0 m-0 mt-1">
                      {errors.Description.message}
                    </p>
                  )}
                </div>
                {/* moving div */}
                <div className="col-md-8 mt-3">
                  <marquee className="p-2 bg-light text-dark">
                  Sample Description; Questions about World History; USA SAT Exam; Inida NEET Exam questions; Chrimas questions; Eiffle Tower;Questions about Taylor shift; 2 questions on islam , 2 on hinduism, 2 on christinaity, 2 on buddism , 2 on judaism; 
                  </marquee>
                </div>
                {/* public availability*/}
                <div className="col-md-8 mt-3">
                  <Controller
                    name="IsPublicVisibility"
                    control={control}
                    render={({ field }) => (
                        <fieldset>
                          <legend>
                          Quiz available to public?
                          </legend>
                          <div className='d-inline-block'>
                          <input
                          type="radio"
                          name="IsPublicVisibility"
                          id="yes-public"
                          value="true"
                          onClick={function(){
                            setIsPublic(true)
                          }}
                        />
                        &nbsp;
                        <label htmlFor="yes-public">Yes</label>&nbsp;&nbsp;
                        <input
                          type="radio"
                          name="IsPublicVisibility"
                          id="no-public"
                          value="false"
                          onClick={function(){
                            setIsPublic(false)
                          }}
                          defaultChecked
                        />
                        &nbsp;
                        <label htmlFor="no-public">No</label>
                            
                          </div>
                          
                        </fieldset>
                    )}
                  />
                </div>
                {/* preview the questions and answers */}
                <div className="col-md-8 mt-3">
                  <Controller
                    name="IsReviewRequired"
                    control={control}
                    render={({ field }) => (
                      
                        <fieldset>
                          <legend>
                          Do you want to preview the question/answers?
                          </legend>
                          <div className='d-inline-block'>
                          <input
                          type="radio"
                          name="IsReviewRequired"
                          id="yes-preview"
                          value="false"
                          onClick={function(){
                            setIsPreview(true)
                          }}
                          
                        />
                        &nbsp;
                        <label htmlFor="yes-preview">Yes</label>&nbsp;&nbsp;
                        <input
                          type="radio"
                          name="IsReviewRequired"
                          id="no-preview"
                          value="false"
                          onClick={function(){
                            setIsPreview(false)
                          }}
                          defaultChecked
                        />
                        &nbsp;
                        <label htmlFor="no-preview">No</label>
                          </div>
                        </fieldset>
                    )}
                  />
                </div>
                {/*is login required */}
                <div className="col-md-8 mt-3">
                  <Controller
                    name="IsPlaySameTimeRequired"
                    control={control}
                    render={({ field }) => (
                      
                        <fieldset>
                          <legend>
                          Multiple players play together?
                          </legend>
                          <div className='d-inline-block'>
                          <input
                          type="radio"
                          name="IsPlaySameTimeRequired"
                          id="yes-login"
                          value="true"
                          onClick={function(){
                            setIsPlaySameTimeRequired(true)
                          }}
                        />
                        &nbsp;
                        <label htmlFor="yes-login">Yes</label>&nbsp;&nbsp;
                        <input
                          type="radio"
                          name="IsPlaySameTimeRequired"
                          id="no-login"
                          value="false"
                          onClick={function(){
                            setIsPlaySameTimeRequired(false)
                          }}
                          defaultChecked
                        />
                        &nbsp;
                        <label htmlFor="no-login">No</label>
                          </div>
                        </fieldset>
                    )}
                  />
                </div>
              </div>

              {/* <SessionCategory></SessionCategory> */}
              {isSessionCodeSuccessful&&<div className="col-lg-8 text-center mx-auto">
                <label className="break-after-all mt-2 mb-2">
                  Session Code for all players to join this session is: &nbsp;
                  <span
                    className="bold"
                    onBlur={(e) => handleInputChange(e, "SessionCode")}
                    suppressContentEditableWarning
                  >
                    <span className="bg-success rounded p-1">{randomCode}</span>
                  </span>
                </label>
              </div>}
              <br />
              {sessionCreated && (
                <>
                  <div className="col-lg-8 text-center mx-auto">
                    <label className="bg-success text-center">
                      {" "}
                      Your session Created Successfully{" "}
                    </label>
                    <button
                      onClick={joinSessionNewWindow}
                      className="break-after-all btn ml-1 px-4 py-2 bg-light text-dark fw-bold ms-2"
                    >
                      join Quiz
                    </button>
                    <button className="break-after-all btn ml-1 px-4 py-2 bg-light text-dark fw-bold ms-2">
                      Close
                    </button>
                  </div>
                </>
              )}
              {errorOccured && (
                <div className="d-flex justify-content-center">
                  <div className="bg-danger p-2 rounded text-center">
                    Error while creating the questions
                    <button
                      onClick={handleRetry}
                      className="break-after-all btn ml-1 px-4 py-2 bg-light text-dark fw-bold ms-2"
                    >
                      Try Again
                    </button>
                  </div>
                </div>
              )}

              {preparingQuestions && (
                <div className="col-lg-8 text-center mx-auto">
                  <label fw-bold> AI in work please wait .... </label>
                </div>
              )}
              {createSession && (
                <div className="text-center">
                  <button
                    type="submit"
                    className="break-after-all btn btn-primary px-4 py-2 fw-bold"
                  >
                    Create this Quiz Session
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateSession;
