import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown"; // Import the Dropdown component from Bootstrap
/*
theme colors  - #007BA7,#DC143C,#50C878,#9966CC,#EAA221,#FF7F50,#40E0D0
*/
const Start = ({ onLogout }) => {
  console.log('onlogout',onLogout)
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const startJoinQuizSession = () => {
    navigate("/joinquizsession");
  };

  const startCreateSession = () => {
    navigate("/createquizsession");
  };

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSignOut = () => {
    // localStorage.setItem('isLoggedIn',false);
    onLogout();
    // console.log('logging out')
    // localStorage.clear();
    // window.location.reload()
  };

  return (
    <section className="text-center">
   
      <div className="container">
        <div className="row vh-100 align-items-center justify-content-center">
          <div className="col-lg-8">
            <h1 className="fw-bold mb-4">Welcome to LearnMist</h1>
            <button
              onClick={startJoinQuizSession}
              className="btn px-4 py-2 bg-light text-dark fw-bold"
            >
              Join Existing Quiz
            </button>
            <br />
            <br />
            <button
              onClick={startCreateSession}
              className="btn px-4 py-2 bg-light text-dark fw-bold"
            >
              Create New Quiz
            </button>
            <div
              className="dropdown"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1000, // Make sure it's on top of other elements
              }}
            >
              <Dropdown align="end" className="hide-dropdown-arrow">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  style={{ border: "none", background: "transparent" }} // Remove background
                >
                  <FontAwesomeIcon className="fs-3" icon={faEllipsisV} color="grey"/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to="/buy-now">Buy Now</Link>                  
                  </Dropdown.Item>
                  <Dropdown.Item>
                  <Link to="/PrivacyPolicy">Privacy Policy</Link>                   
                  </Dropdown.Item>
                  <Dropdown.Item>
                  <Link to="/TermsOfUse">Terms Of Use</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                  <Link to="/DeleteData">Data Delete policy</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                  <Link to="/DeleteMyData">Delete all my data</Link>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleSignOut}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>                
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Start;
