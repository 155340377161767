import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import Router
import Logout from "./components/Logout";
import Login from "./components/Login";
import Start from "./components/Start";
import CreateSession from "./components/CreateSession";
import Result from "./components/Result";
import Quiz from "./components/Quiz";
import JoinQuizSession from "./components/JoinQuizSession";
import SessionStatus from "./components/SessionStatus";
import ShowQuizAndResults from "./components/ShowQuizAndResults";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import DeleteData from "./components/DeleteData";
import DeleteMyData from "./components/DeleteMyData";
import { gapi } from "gapi-script";
import { toast } from "react-toastify";
import BuyNow from "./components/BuyNow";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SuccessfulPayment from "./components/SuccessfulPayment";
import CancelPayment from "./components/CancelPayment";
import { Link } from "react-router-dom";
import Home from './images/house-solid.svg'
import PreviewQuestions from './components/PreviewQuestions';
import {websiteurl,STRIPE_KEY} from './globals';
import './App.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { byPrefixAndName } from '@awesome.me/kit-KIT_CODE/icons'
// import ScriptTag from 'react-script-tag';
function App() {
  const clientID =
    "356394307734-0576bulqkuvvtgtvbsr1v4mm24o1vcad.apps.googleusercontent.com";
  const facebookAppID = "1168919231111993";
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Handle Google Login
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client
        .init({
          client_id: clientID,
          scope: "openid profile email",
        })
        .then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
          if (authInstance) {
            setIsLoggedIn(authInstance.isSignedIn.get());
            authInstance.isSignedIn.listen(handleLoginStatusChange);
          } else {
            // Handle the case when authInstance is null
            console.error("Auth instance is null");
            toast.success("Login successful");
          }
        })
        .catch((error) => {
          // Handle initialization error
          console.error("Error initializing Google API:", error);
          toast.error("Login failed");
        });
    });
  }, [clientID]);

  const handleLoginStatusChange = (isSignedIn) => {
    setIsLoggedIn(isSignedIn);
    localStorage.setItem("loginMethod", "GOOGLE");
  };

  useEffect(() => {
    // When isLoggedIn changes, store it in Local Storage
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  // Check Local Storage on page load
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    if (storedIsLoggedIn === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  // Facebook Handle

  useEffect(() => {
    // Initialize the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: facebookAppID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0",
      });
    };

    // Load the Facebook SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const handleFacebookLogin = () => {
    window.FB.login(function (response) {
      if (response.authResponse) {
        localStorage.setItem("loginMethod", "FACEBOOK");
        console.log("Logged in successfully", response);
        toast.success("Login Facebook successful");
        // Handle successful login
        setIsLoggedIn(true);
      } else {
        console.log("Login failed or canceled", response);
        toast.error("Login Facebook failed");
        // Handle login failure
        setIsLoggedIn(false);
      }
    });
  };

  const handleFacebookLogout = () => {
    window.FB.logout(function (response) {
      toast.success("Logout successful");
    });
    setIsLoggedIn(false);
  };


  const stripePromise = loadStripe(
  STRIPE_KEY
    // "pk_test_51OCYGaGJVWLloJD8soM19joLgxc7U02JP73BXJMbDt2bWsfol8TDz9aD14Wjrz3gZscwRb06roU33prUgZS1Pft90077MaoY6Q"
    //"pk_test_51ODnt9JThuQlLCkfuLYHrR78scDMD7ZfiDc1ElaDTkJurbDL8olqLrpjcANLqro5xs0d5uZstk3Dglzb6MWmvadO00sPbe30XR"
  ); 
  const [theme,setTheme] = useState('dark');
  
  return (
    <main className={theme}>
    <Elements stripe={stripePromise} >
      <nav>
      <a href={websiteurl}>
      <img src={Home} alt="home" className='home-icon'/>
      </a>
      <div className="theme">
      
      </div>
      </nav>
      <Router>
        {console.log(isLoggedIn)}
        {isLoggedIn ? (
          <>
            <Logout
              onLogout={handleLoginStatusChange}
              handleFacebookLogout={handleFacebookLogout}
            />
            {/* <ScriptTag type="text/javascript" src="https://checkout.stripe.com/checkout.js" /> */}
            
            <Routes>
              <Route
                path="/"
                element={<Start onLogout={handleLoginStatusChange} />}
              />
              <Route path="/createquizsession" element={<CreateSession />} />
              <Route path="/result" element={<Result />} />
              <Route path="/quiz" element={<Quiz />} />
              <Route path="/joinquizsession" element={<JoinQuizSession />} />
              <Route path="/sessionstatus" element={<SessionStatus />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/TermsOfUse" element={<TermsOfUse />} />
              <Route path="/DeleteMyData" element={<DeleteMyData />} />          
              <Route path="/DeleteData" element={<DeleteData />} />    
              
              <Route path="/buy-now" element={<BuyNow />} />
              <Route path="/SuccessfulPayment" element={<SuccessfulPayment />} />
              <Route path="/cancelpayment" element={<CancelPayment />} />
              <Route path="/start" element={<Start />} />
              <Route path="/previewQuestions" element={<PreviewQuestions />} />

              <Route
                path="/showquizandresults"
                element={<ShowQuizAndResults />}
              />
            </Routes>
           
            
          </>
        ) : (
          <>
            <Login
              onLogin={handleLoginStatusChange}
              handleFacebookLogin={handleFacebookLogin}
            />
            {/* <button onClick={handleFacebookLogin}>Login with Facebook</button> */}
          </>
        )}
      </Router>
    </Elements>
    </main>
  );
}

export default App;
